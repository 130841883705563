import { insertTextAtPosition } from '../../utils/InsertTextAtPosition';
import { TextAreaTextApi } from '../../commands';
import { insertBeforeEachLine } from '../../commands/list';

/**
 * - `13` - `Enter`
 * - `9` - `Tab`
 */
function stopPropagation(e) {
  e.stopPropagation();
  e.preventDefault();
}
export default function handleKeyDown(e, tabSize, defaultTabEnable) {
  if (tabSize === void 0) {
    tabSize = 2;
  }
  if (defaultTabEnable === void 0) {
    defaultTabEnable = false;
  }
  var target = e.target;
  var starVal = target.value.substr(0, target.selectionStart);
  var valArr = starVal.split('\n');
  var currentLineStr = valArr[valArr.length - 1];
  var textArea = new TextAreaTextApi(target);

  /**
   * `9` - `Tab`
   */
  if (!defaultTabEnable && e.code && e.code.toLowerCase() === 'tab') {
    stopPropagation(e);
    var space = new Array(tabSize + 1).join('  ');
    if (target.selectionStart !== target.selectionEnd) {
      var _star = target.value.substring(0, target.selectionStart).split('\n');
      var _end = target.value.substring(0, target.selectionEnd).split('\n');
      var modifiedTextLine = [];
      _end.forEach((item, idx) => {
        if (item !== _star[idx]) {
          modifiedTextLine.push(item);
        }
      });
      var modifiedText = modifiedTextLine.join('\n');
      var oldSelectText = target.value.substring(target.selectionStart, target.selectionEnd);
      var newStarNum = target.value.substring(0, target.selectionStart).length;
      textArea.setSelectionRange({
        start: target.value.indexOf(modifiedText),
        end: target.selectionEnd
      });
      var modifiedTextObj = insertBeforeEachLine(modifiedText, e.shiftKey ? '' : space);
      var text = modifiedTextObj.modifiedText;
      if (e.shiftKey) {
        text = text.split('\n').map(item => item.replace(new RegExp("^" + space), '')).join('\n');
      }
      textArea.replaceSelection(text);
      var startTabSize = e.shiftKey ? -tabSize : tabSize;
      var endTabSize = e.shiftKey ? -modifiedTextLine.length * tabSize : modifiedTextLine.length * tabSize;
      textArea.setSelectionRange({
        start: newStarNum + startTabSize,
        end: newStarNum + oldSelectText.length + endTabSize
      });
    } else {
      return insertTextAtPosition(target, space);
    }
  } else if (e.keyCode === 13 && e.code.toLowerCase() === 'enter' && (/^(-|\*)\s/.test(currentLineStr) || /^\d+.\s/.test(currentLineStr))) {
    /**
     * `13` - `Enter`
     */
    stopPropagation(e);
    var startStr = '\n- ';
    if (currentLineStr.startsWith('*')) {
      startStr = '\n* ';
    }
    if (currentLineStr.startsWith('- [ ]')) {
      startStr = '\n- [ ] ';
    } else if (currentLineStr.startsWith('- [X]')) {
      startStr = '\n- [X] ';
    }
    if (/^\d+.\s/.test(currentLineStr)) {
      startStr = "\n" + (parseInt(currentLineStr) + 1) + ". ";
    }
    return insertTextAtPosition(target, startStr);
  }
}